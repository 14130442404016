import React, {useState } from "react";
import ContentLayout from "../../components/ContentLayout/ContentLayout";
import { ButtonPrimary } from "../../components/Button/Button";
import { Spinner } from "../../components/Spinner/Spinner";
import { AxiosError} from "axios";
import { rePrintToken } from "../../services/token";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import * as yup from "yup";
import CreditTokenModal from "../../components/Modals/CreditTokenModal/CreditTokenModal";
import ReportIcon from "../../assets/img/reprintIcon.svg"

const schema = yup.object().shape({
  MeterNumber: yup
    .string()
    .matches(/^\d+$/, "Meter number must be numeric")
    .required("Meter number is a required field"),
});

const ReprintToken: React.FC = () => {
  const [apiData, setApiData] = useState<any>(null); // State to hold API data
  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { mutateAsync: getMeterNumber, isLoading } = useMutation(rePrintToken, {
    onSuccess: async ({ data }) => {
      setApiData(data); // Update state with API data
      setShowModal(true); // Show modal when API call is successful
    },
    onError: (error: AxiosError) => {
      const errorMessage =
        //@ts-ignore
        error?.response?.data?.message || error?.response?.data?.errors?.[0] ||
        "Unknown error occurred";
      toast.error(errorMessage);
    },
  });
  const onSubmit = (data: any) => {
    console.log(data, "myData");

    getMeterNumber({
      ...data,
    });
  };
  console.log(apiData?.data, "myData");

  

  return (
    <ContentLayout
    iconName={ReportIcon}
    headerName="Re-print Credit Token"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-1/8 md:w-1/4 text-black text-[24px] md:text-[32px] font-semibold leading-[24.20px] md:leading-[38.40px] ml-[2px] md:ml-[50px] mt-[50px]">
          Please input your meter number
        </div>

        <div className="flex flex-col justify-start items-start  gap-[32px] mt-[20px] md:mt-[40px] mx-auto max-w-[450px] md:ml-[50px]">
        <div className="w-full mb-3">
            <label className="block text-zinc-900 text-sm font-medium mb-2">
              Meter number{" "}
            </label>
            <input
              className="w-full h-10 px-3 py-1.5 rounded-[10px] border border-zinc-200 outline-none"
              type="text"
              {...register("MeterNumber")}
              autoComplete="new"
              onFocus={(e) => e.target.removeAttribute("readonly")}
              readOnly
              onInput={(e) => {
                const input = e.target as HTMLInputElement;
                input.value = input.value.replace(/[^0-9]/g, '');
              }}
            />
            <p className="text-xs text-red-600">
              {errors?.MeterNumber?.message}
            </p>
          </div>
        </div>

        <div className="mt-8 md:ml-[50px] mx-auto">
          <ButtonPrimary
            label={isLoading ? <Spinner /> : "Re-print credit token"}
            type="submit"
            disabled={isLoading}
            width="41%"
          />
        </div>
      </form>
      <CreditTokenModal isOpen={showModal} setIsOpen={setShowModal} tokenData={apiData?.data} onClose={() => setShowModal(false)} />
      
    </ContentLayout>
  );
};

export default ReprintToken;
