import { FC} from "react";
import moment from "moment";

export const AuditTable: FC<{
  apiData: any;
  refetch?: any;
}> = ({ apiData, refetch }) => {
  return (
    <table
      className="w-full font-inter"
      style={{ fontSize: "14px", cursor: "pointer" }}
    >
      <thead className={"m-6 bg-neutral-50 text-slate-900"}>
        <tr className="border-b rounded-lg">
          <th className="text-xs text-[#1A1B1D] font-medium py-3">Login ID</th>
          <th className="text-xs text-[#1A1B1D] font-medium">Operator Name</th>
          <th className="text-xs text-[#1A1B1D] font-medium">Activity</th>
          <th className="text-xs text-[#1A1B1D] font-medium">Date</th>
        </tr>
      </thead>
      <tbody>
        {apiData.map((item: any, index: number) => {
          return (
            <tr className="border-b bg-white border-zinc-100" key={index}>
              <th className="text-xs text-[#1A1B1D] px-2 py-5 font-normal">
                {item.logID}
              </th>
              <th className="text-xs text-[#1A1B1D] px-2 py-3 font-normal">
                {item.fullName}
              </th>
              <th className="text-xs text-[#1A1B1D] px-2 py-3 font-normal">
                {item.message}
              </th>
              <th className="text-xs text-[#1A1B1D] px-2 py-3 font-normal">
                {moment(item.dateTime).format('DD/MM/YYYY hh:mm A')}
              </th>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
