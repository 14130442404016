import React, { ReactElement, useEffect, useState } from "react";
import { Select, AutoComplete, Modal} from "antd";
import { ButtonPrimary } from "../Button/Button";
import { ButtonOutline } from "../Button/ButtonOutlined";
import useAllBusinessUnit from "../../hooks/useAllBusiness";
import useAllTariff from "../../hooks/useAllTariffs";

interface FilterModalProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  onApply: (filters: {
    noOfDaysNotVended: number | null;
    businessName: string | null;
    tariffName: number | null;
  }) => void;
}

const { Option } = Select;

const MonthsWithoutSalesFilter: React.FC<FilterModalProps> = ({
  isOpen,
  setIsOpen,
  onApply,
}): ReactElement => {
  const { buinessUnit, getBusiness } = useAllBusinessUnit();
  const { tariffs, getTariffs } = useAllTariff();

  const [dates, setDates] = useState<[string | null, string | null]>([
    null,
    null,
  ]);
  const [tariffCode, setTariffCode] = useState<string>("");
  const [businessName, setBusinessName] = useState<string | null>(null);

  const [businessOptions, setBusinessOptions] = useState<any[]>([]);
  const [tariffOptions, setTariffOptions] = useState<any[]>([]);
  const [noOfDaysNotVended, setNumberOfDaysNotVended] = useState<number | null>(null);
  const [tariffMap, setTariffMap] = useState<{ [key: string]: number }>({});
  const [dropdownOpenBus, setDropdownOpenBus] = useState(false); // State to control dropdown visibility
  const [dropdownOpenTaf, setDropdownOpenTaf] = useState(false); // State to control dropdown visibility
  const [businessDropdownOpen, setBusinessDropdownOpen] = useState(false);
  const [tariffDropdownOpen, setTariffDropdownOpen] = useState(false);

  useEffect(() => {
    getTariffs();
    getBusiness();
  }, []);

  useEffect(() => {
    const map: { [key: string]: number } = {};
    tariffs.forEach((tariff: any) => {
      map[tariff.tariffCode] = tariff.tariffID;
    });
    setTariffMap(map);
  }, [tariffs]);

  const handleBusinessChange = (value: string) => {
    setBusinessName(value || null);
  };

  const handleTariffChange = (value: string) => {
    setTariffCode(value);
  };

  const handleSearchBusiness = (searchText: string) => {
    if (searchText) {
      const filteredOptions = buinessUnit.filter((business: any) =>
      business.name.toLowerCase().includes(searchText.toLowerCase())      );
      setBusinessOptions(filteredOptions);
    } else {
      setBusinessOptions(buinessUnit); // Reset to all users if search text is empty
    }
    setDropdownOpenBus(true); // Ensure dropdown stays open while typing
  };

  
  const handleSearchTariffs = (searchText: string) => {
    if (searchText) {
      const filteredOptions = tariffs.filter((tariff: any) =>
      tariff.tariffCode.toLowerCase().includes(searchText.toLowerCase())      
    );
    setTariffOptions(filteredOptions);
    } else {
      setTariffOptions(tariffs); // Reset to all users if search text is empty
    }
    setDropdownOpenTaf(true); // Ensure dropdown stays open while typing
  };

  const handleNumberOfDaysChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNumberOfDaysNotVended(parseInt(e.target.value) || null);
  };

  const handleApply = () => {
    const tariffID = tariffMap[tariffCode] || null;
    onApply({
      businessName,
      tariffName: tariffID,
      noOfDaysNotVended,
    });
    setIsOpen(false);
  };

  const handleBusinessFocus = () => {
    setDropdownOpenBus(true); // Open dropdown on focus
  };

  const handleBusinessBlur = () => {
    setDropdownOpenBus(false); // Close dropdown on blur
  };

  const handleFocusTarif = () => {
    setDropdownOpenTaf(true); // Open dropdown on focus
  };

  const handleBlurTarif = () => {
    setDropdownOpenTaf(false); // Close dropdown on blur
  };
  const resetFilters = () => {
    setDates([null, null]);
    setBusinessName(null);
    setTariffCode('');
    setNumberOfDaysNotVended(null)
    setBusinessDropdownOpen(false);
    setTariffDropdownOpen(false);
  };

  useEffect(() => {
    if (tariffs) {
      setTariffOptions(tariffs); // Initialize options with all users
    }
  }, [tariffs]);
  

  useEffect(() => {
    if (buinessUnit) {
      setBusinessOptions(buinessUnit); // Initialize options with all users
    }
  }, [buinessUnit]);

  return (
    <Modal
    open={isOpen}
      onCancel={() => setIsOpen(false)}
      footer={null}
      width={430} // Explicitly set the width to match the content
      closeIcon={null}
      style={{ position: 'absolute', top: '25%', right: '10%' }} // Set position here
      mask={false}
    >
        <div className="w-[368px] h-[445px] relative bg-white rounded-[20px] shadow">
          <div className="ml-[24px] mt-[10px] pt-4 text-zinc-900 text-base font-semibold font-['Uncut Sans Variable'] leading-tight">
            Filters
          </div>

          <div className="h-[152px] left-[24px] top-[72px] absolute flex-col justify-start items-start gap-5 inline-flex">
            <div className="self-stretch h-[66px] flex-col justify-start items-start gap-1.5 flex">
              <div className="text-center text-zinc-900 text-sm font-medium font-['Uncut Sans Variable'] leading-tight">
                Business unit
              </div>
              <AutoComplete
              value={businessName}
              placeholder="Select business unit"
              style={{ width: '320px' }}
              defaultActiveFirstOption={false}
              filterOption={false}
              open={dropdownOpenBus}
              onFocus={handleBusinessFocus} // Open dropdown on focus
              onBlur={handleBusinessBlur} // Close dropdown on blur
              onChange={handleBusinessChange}
              onSearch={handleSearchBusiness}
              onSelect={(value) => setDropdownOpenBus(false)} // Close dropdown when an option is selected
            >
              {businessOptions.map((business: any) => (
                <Option key={business.buid} value={business.buid}>
                  {business.buid}
                </Option>
              ))}
            </AutoComplete>
            </div>

            <div className="self-stretch h-[66px] flex-col justify-start items-start gap-1.5 flex">
              <div className="text-center text-zinc-900 text-sm font-medium font-['Uncut Sans Variable'] leading-tight">
                Tariff
              </div>
              <AutoComplete
              value={tariffCode}
              placeholder="Select tariff"
              style={{ width: '320px' }}
              defaultActiveFirstOption={false}
              filterOption={false}
              open={dropdownOpenTaf}
              onFocus={handleFocusTarif} // Open dropdown on focus
              onBlur={handleBlurTarif} // Close dropdown on blur
              onChange={handleTariffChange}
              onSearch={handleSearchTariffs}
              onSelect={(value) => setDropdownOpenTaf(false)} // Close dropdown when an option is selected

            >
              {tariffOptions.map((tariff: any) => (
                <Option key={tariff.tariffCode} value={tariff.tariffCode}>
                  {tariff.tariffCode}
                </Option>
              ))}
            </AutoComplete>
            </div>

            <div className="self-stretch h-[66px] flex-col justify-start items-start gap-1.5 flex">
              <div className="w-[450px] mx-auto mb-3">
                <label className="block text-zinc-900 text-sm font-medium mb-2">
                  Number of days not vended{" "}
                </label>
                <input
                   className="w-80 h-10 px-3 py-1.5 rounded-[10px] border border-zinc-200 outline-none"
                   type="number"
                   name="noOfDaysNotVended"
                   onChange={handleNumberOfDaysChange}
                   value={noOfDaysNotVended ?? ""}
                   autoComplete="new"
                   onFocus={(e) => e.target.removeAttribute("readonly")}
                   readOnly
                />
              </div>
            </div>

          </div>

          <div className="left-[90px] top-[350px] absolute justify-start items-start gap-4 inline-flex">
            <ButtonOutline width="120px" label="Reset filter"  handleClick={resetFilters}/>
            <div className="w-[120px] h-10 px-3.5 py-1.5 bg-gradient-to-b from-white to-white rounded-[10px] shadow border border-white/opacity-20 justify-center items-center gap-2 flex">
              <ButtonPrimary
                width="120px"
                label="Apply"
                handleClick={handleApply}
              />
            </div>
          </div>
        </div>
    </Modal>
  );
};

export default MonthsWithoutSalesFilter;
