import React, { ReactElement, useEffect, useState } from "react";
import { Select, AutoComplete, DatePicker, Modal } from "antd";
import { ButtonPrimary } from "../../Button/Button";
import { ButtonOutline } from "../../Button/ButtonOutlined";
import useAllTariff from "../../../hooks/useAllTariffs";
import type { DatePickerProps } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { toast } from "react-toastify";
import './daily.css'


interface FilterModalProps {
  isOpen: boolean;
  setIsOpen: any;
  onApply: (filters: {
    tariffName: number | null;
    startDate: string | null;
    endDate: string | null;
  }) => void;
}

const { Option } = Select;

const DailySaleFilter: React.FC<FilterModalProps> = ({
  isOpen,
  setIsOpen,
  onApply,
}): ReactElement => {
  const { RangePicker } = DatePicker;
  const { tariffs, getTariffs } = useAllTariff();
  const [value, setValue] = useState<string | undefined>();
  const [dates, setDates] = useState<[Dayjs | null, Dayjs | null]>([
    null,
    null,
  ]);
  const [tariffOptions, setTariffOptions] = useState<any[]>([]);
  const [tariffMap, setTariffMap] = useState<{ [key: string]: number }>({});
  const [tariffCode, setTariffCode] = useState<string>("");
  const [dropdownOpenTaf, setDropdownOpenTaf] = useState(false); // State to control dropdown visibility
  const [tariffDropdownOpen, setTariffDropdownOpen] = useState(false);

  const handleDateChange = (dates: any, dateStrings: [string, string]) => {
    setDates(dates);
  };

  const handleTariffChange = (value: string) => {
    setTariffCode(value);
  };

  const handleFocusTarif = () => {
    setDropdownOpenTaf(true); // Open dropdown on focus
  };

  const handleBlurTarif = () => {
    setDropdownOpenTaf(false); // Close dropdown on blur
  };

  const handleSearchTariffs = (searchText: string) => {
    if (searchText) {
      const filteredOptions = tariffs.filter((tariff: any) =>
        tariff.tariffCode.toLowerCase().includes(searchText.toLowerCase())
      );
      setTariffOptions(filteredOptions);
    } else {
      setTariffOptions(tariffs); // Reset to all users if search text is empty
    }
    setDropdownOpenTaf(true); // Ensure dropdown stays open while typing
  };

  useEffect(() => {
    if (tariffs) {
      setTariffOptions(tariffs); // Initialize options with all users
    }
  }, [tariffs]);

  useEffect(() => {
    getTariffs();
  }, []);

  useEffect(() => {
    const map: { [key: string]: number } = {};
    tariffs.forEach((tariff: any) => {
      map[tariff.tariffCode] = tariff.tariffID;
    });
    setTariffMap(map);
  }, [tariffs]);

  const handleApply = () => {
    if (!dates[0] || !dates[1]) {
      toast.error("Please select both start and end dates.");
      return;
    }
    const tariffID = tariffMap[tariffCode] || null;
    onApply({
      startDate: dates[0]?.format("YYYY-MM-DD") || null,
      endDate: dates[1]?.format("YYYY-MM-DD") || null,
      tariffName: tariffID,
    });
    setIsOpen(false);
  };

  const resetFilters = () => {
    setDates([null, null]);
    setTariffCode("");
    setTariffDropdownOpen(false);
  };
  const disabledMonthsDate: DatePickerProps["disabledDate"] = (
    current: Dayjs,
    { from }: { from?: Dayjs }
  ) => {
    const today = dayjs().startOf("day");

    // Disable dates beyond today
    if (current.isAfter(today)) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      footer={null}
      width={430} // Explicitly set the width to match the content
      closeIcon={null}
      style={{ position: "absolute", top: "25%", right: "10%" }} // Set position here
      mask={false}
    >
      <div className="w-[368px] h-[402px] relative bg-white rounded-[20px] shadow">
        <div className="ml-[24px] mt-[10px] pt-4 text-zinc-900 text-base font-semibold font-['Uncut Sans Variable'] leading-tight">
          Filters
        </div>

        <div className="h-[152px] left-[24px] top-[72px] absolute flex-col justify-start items-start gap-5 inline-flex">
          <div className="self-stretch h-[66px] flex-col justify-start items-start gap-1.5 flex">
            <div className="text-center text-zinc-900 text-sm font-medium font-['Uncut Sans Variable'] leading-tight">
              Tariff
            </div>
            <AutoComplete
              value={tariffCode}
              placeholder="Select tariff"
              style={{ width: "320px" }}
              defaultActiveFirstOption={false}
              filterOption={false}
              open={dropdownOpenTaf}
              onFocus={handleFocusTarif} // Open dropdown on focus
              onBlur={handleBlurTarif} // Close dropdown on blur
              onChange={handleTariffChange}
              onSearch={handleSearchTariffs}
              onSelect={(value) => setDropdownOpenTaf(false)} // Close dropdown when an option is selected
            >
              {tariffOptions.map((tariff: any) => (
                <Option key={tariff.tariffCode} value={tariff.tariffCode}>
                  {tariff.tariffCode}
                </Option>
              ))}
            </AutoComplete>
          </div>

          <div className="self-stretch h-[66px] flex-col justify-start items-start gap-1.5 flex">
            <div className="text-center text-zinc-900 text-sm font-medium font-['Uncut Sans Variable'] leading-tight">
              Period
            </div>

            <RangePicker
              value={dates}
              onChange={handleDateChange}
              disabledDate={disabledMonthsDate}
              className="w-full sm:w-[320px]" 
              style={{ width: '100%' }}
            />
          </div>
        </div>

        <div className="left-[90px] top-[342px] absolute justify-start items-start gap-4 inline-flex">
          <ButtonOutline
            width="120px"
            label="Reset filter"
            handleClick={resetFilters}
          />
          <div className="w-[120px] h-10 justify-center items-center gap-2 flex">
            <ButtonPrimary
              width="120px"
              label="Apply"
              handleClick={() => {
                handleApply();
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DailySaleFilter;
