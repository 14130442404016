import React, { useState, useEffect } from "react";
import ContentLayout from "../../../components/ContentLayout/ContentLayout";
import ReportIcon from "../../../assets/img/reportIcon.svg";
import { SearchInput } from "../../../components/SearchInput/SearchInput";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useQuery, useMutation } from "react-query";
import {
  searchCustomerSetup,
  downloadCustomerSetup,
} from "../../../services/report";
import downloadIcon from "../../../assets/img/whitedownload.svg";
import sortIcon from "../../../assets/img/Sort.svg";
import { CusSetupTable } from "./record";
import Skeleton from "react-loading-skeleton";
import { EmptyState } from "../../../components/EmptyState/EmptyState";
import { ErrorCard } from "../../../components/ErrorCard/ErrorCard";
import moment from "moment";
import CusPopulationModal from "../../../components/Modals/CusPopulationFilter/CusPopulationFilter";
import DownloadModal from "../../../components/Modals/DownloadModal";
import PaginationCom from "../../../components/PaginationCom/paginationCom";

const CustomerSetup: React.FC = () => {
  const [searchPhrase, setSearchPhrase] = useState("");
  const [downloadType, setDownloadType] = useState("");
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [isSearching, setIsSearching] = useState(false);

  const [filters, setFilters] = useState({
    from: 1,
    pageSize: 10,
    parameter: {
      query: "",
      startDate: moment().subtract(30, "days").format("yy-MM-DD"),
      endDate: moment().format("yy-MM-DD"),
      operator: "",
      buid: "",
      tariffID: 0,
    },
  });

  const {
    mutateAsync,
    isLoading: isDownloading,
    isError: isDownloadErr,
    error: downloadError,
    data,
  } = useMutation(downloadCustomerSetup, {
    onSuccess: (response) => {
      const mimeType =
        downloadType === "pdf" ? "application/pdf" : "application/vnd.ms-excel";
      const fileExtension = downloadType === "pdf" ? "pdf" : "csv";

      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: mimeType });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `customer-setup_report.${fileExtension}`);
      document.body.appendChild(link); // Append link to the body
      link.click();
      link.remove(); // Remove link from the body after clicking

      toast.success("Downloading...");
      setShowDownloadModal(false);
    },
    onError: (error: AxiosError) => {
      toast.error("Error downloading");
    },
  });

  // const handleSearch = (input: string) => {
  //   setSearchPhrase(input);
  //   if (response?.data?.data?.length > 0) {
  //     const filtered = response?.data.data.filter((record: any) =>
  //       Object.values(record).some((value: any) =>
  //         value?.toString().toLowerCase().includes(input.toLowerCase())
  //       )
  //     );
  //     setFilteredData(filtered);
  //   }
  // };

  const handleSearch = (input: string) => {
    setSearchPhrase(input);
    if (input === "") {
      setIsSearching(false);
      setFilteredData(response?.data?.data || []);
    } else {
      setIsSearching(true);
      if (response?.data?.data?.length > 0) {
        const filtered = response?.data.data.filter((record: any) =>
          Object.values(record).some((value: any) =>
            value?.toString().toLowerCase().includes(input.toLowerCase())
          )
        );
        setFilteredData(filtered);
      } else {
        setFilteredData([]);
      }
    }
  };

  const handlePageChange = (page: number) => {
    setFilters({
      ...filters,
      from: page,
    });
  };

  const downloadFileType = () => {
    mutateAsync({
      ...filters,
      downloadType: downloadType,
    });
  };

  const openFilter = () => {
    setShowFilterModal(true);
  };

  const {
    isLoading,
    isError,
    data: response,
    error,
    refetch,
  } = useQuery([{ filters }], () => searchCustomerSetup(filters), {
    enabled: true,
    retry: false,
    onSuccess: (res) => {},
    onError: (err) => {},
  });

  const handleDownloadType = (type: string) => {
    setDownloadType(type);
    setShowDownloadModal(false); // Close modal
  };

  const handleApplyFilters = ({
    operatorName,
    startDate,
    endDate,
    tariffName,
    businessName,
  }: {
    operatorName: string | undefined;
    startDate: string | null;
    endDate: string | null;
    businessName: string | null;
    tariffName: number | null;
  }) => {
    console.log(operatorName, startDate, endDate, "apply");

    setFilters({
      ...filters,
      parameter: {
        ...filters.parameter,
        operator: operatorName || "",
        startDate:
          startDate || moment().subtract(30, "days").format("yy-MM-DD"),
        endDate: endDate || moment().format("yy-MM-DD"),
        buid: businessName || "",
        tariffID: tariffName || 0,
      },
    });
  };

  useEffect(() => {
    if (downloadType) {
      downloadFileType();
    }
  }, [downloadType]);

  useEffect(() => {
    if (response && response.data && searchPhrase === "") {
      setFilteredData(response.data.data);
    }
  }, [response, searchPhrase]);

  return (
    <ContentLayout iconName={ReportIcon} headerName="Reports">
      <div className="m- ">
        <div className="ml-[30px] flex flex-row justify-between">
          <div className="text-zinc-900 text-xl font-semibold font-['Uncut Sans Variable'] leading-tight">
            Customer setup
          </div>

          {!isSearching && response?.data?.data?.length > 0 && filteredData?.length > 0 && (
            <div
              className="w-[200px] h-10 px-3.5 py-1.5 bg-[#00A859] rounded-[10px] shadow border border-white/opacity-20 justify-center items-center gap-2 inline-flex cursor-pointer"
              onClick={() => setShowDownloadModal(!showDownloadModal)}
            >
              <img
                className="w-4 h-4 relative rounded-[5px]"
                src={downloadIcon}
                alt=""
              />
              <div className="text-center text-white text-sm font-medium font-['Uncut Sans Variable'] leading-tight">
                Download report
              </div>
              {showDownloadModal && (
                <DownloadModal
                  openModal={showDownloadModal}
                  setOpenModal={setShowDownloadModal}
                  handleDownloadType={handleDownloadType}
                />
              )}
            </div>
          )}
        </div>

        {/* filters */}
        <div className="flex flex-row mt-4">
          <div className="py-6 px-10">
            <SearchInput
              value={searchPhrase}
              handleSearch={handleSearch}
              isDisabled={response?.data?.data?.length === 0}
            />
          </div>

          <div className="py-6">
            <div
              className="w-[82px] h-8 px-3 py-1.5 rounded-[10px] shadow justify-start items-center gap-2 inline-flex border cursor-pointer"
              onClick={openFilter}
            >
              <img className="w-4 h-4 relative" src={sortIcon} alt="" />
              <div className="text-center text-gray-700 text-sm font-medium font-['Uncut Sans Variable'] leading-tight">
                Filter
              </div>
            </div>
          </div>
          {showFilterModal && (
            <CusPopulationModal
              isOpen={showFilterModal}
              setIsOpen={setShowFilterModal}
              onApply={handleApplyFilters}
            />
          )}
        </div>

        {isLoading && (
          <div className="pt-8">
            <Skeleton className="px-5 py-3 my-2 h-32" count={5} />
          </div>
        )}
        {isError && <ErrorCard />}
        <>
        {((isSearching && filteredData?.length === 0) ||
            (!isSearching && response?.data?.data?.length === 0)) &&
          !isError ? (
            <div className="mt-8">
              <EmptyState />
            </div>
          ) : !isError ? (
            <div className="mt-8">
              <CusSetupTable apiData={filteredData} refetch={refetch} />
            </div>
          ) : null}

              {!isSearching && response?.data?.data?.length > 0 && filteredData?.length > 0 && (
                <div className="mt-8 pl-6">
                <PaginationCom
                  pageSize={filters.pageSize}
                  totalResults={response?.data?.totalCount}
                  handlePageChange={handlePageChange}
                  currentPage={filters.from}
                />
              </div>
              )}            
          </>

        
      </div>
    </ContentLayout>
  );
};

export default CustomerSetup;
