import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import qs from 'qs'
import { deleteLocalState, saveLocalState } from '../services/localStorage'

export const responseInterceptor = (response: AxiosResponse<any, any>) => {
    return response
}

export const responseErr = (
    err: AxiosError,
    instance: AxiosInstance,
    url: string
) => {
    const originalConfig = err.config
    if (err.response) {
       

        // Access Token was expired
        // @ts-ignore
        if (err.response.status === 401 && !originalConfig._retry) {
            deleteLocalState('EMSUser')
            window.location.href = '/'
        }
        if (err.response.status === 403 && err.response.data) {
            return Promise.reject(err.response.data)
        }
        return Promise.reject(err)
    }

    // return  err
    return Promise.reject(err)
}

export async function getToken(refreshToken: string, url: string) {
    const config = {}
    const data = {
        refreshToken,
    }
    const result = await axios.post(`${url}` || '', qs.stringify(data), config)
    await saveLocalState('EMSUser', result?.data?.token)
    return result?.data?.token
}
