import { FC} from "react";
import moment from "moment";

export const MonthWithoutSalesTable: FC<{
  apiData: any;
  refetch?: any;
}> = ({ apiData, refetch }) => {
  return (
    <div className="w-full overflow-x-auto">
    <table
        className="w-full font-inter min-w-max"
        style={{ fontSize: "14px", cursor: "pointer" }}
    >
      <thead className={"m-6 bg-neutral-50 text-slate-900"}>
      <tr className="border-b rounded-lg">
          <th className="text-xs text-[#1A1B1D] font-medium py-3">Business unit</th>
          <th className="text-xs text-[#1A1B1D] font-medium">Customer name</th>
          <th className="text-xs text-[#1A1B1D] font-medium">Meter number</th>
          <th className="text-xs text-[#1A1B1D] font-medium">Tariff</th>
          <th className="text-xs text-[#1A1B1D] font-medium">Address</th>
          <th className="text-xs text-[#1A1B1D] font-medium">Total transactions</th>
          <th className="text-xs text-[#1A1B1D] font-medium">Last transaction date</th>
        </tr>
      </thead>
      <tbody>
        {apiData.map((item: any, index: number) => {
          return (
            <tr className="border-b bg-white border-zinc-100" key={index}>
              <th className="text-xs text-[#1A1B1D] px-2 py-5 font-normal">
                {item.businessUnit}
              </th>
              <th className="text-xs text-[#1A1B1D] px-2 py-5 font-normal">
                {item.customerName}
              </th>
              <th className="text-xs text-[#1A1B1D] px-2 py-5 font-normal">
                {item.meterNo}
              </th>
              <th className="text-xs text-[#1A1B1D] px-2 py-5 font-normal">
                {item.tariffCode}
              </th>
              <th className="text-xs text-[#1A1B1D] px-2 py-5 font-normal">
                {item.address}
              </th>
              <th className="text-xs text-[#1A1B1D] px-2 py-5 font-normal">
                {item.transactionCount}
              </th>
              <th className="text-xs text-[#1A1B1D] px-2 py-5 font-normal">
              {moment(item.lastTransactionDateTime).format('DD/MM/YYYY h:mm a')}
              </th>
            </tr>
          );
        })}
      </tbody>
    </table>
    </div>
  );
};
