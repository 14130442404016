import React, { ReactElement } from "react";
import { Modal } from "antd";

interface DownloadModalProps {
  openModal: boolean;
  setOpenModal: (open: boolean) => void; // Explicitly type setOpenModal
  handleDownloadType: (type: string) => void; // Add handleDownloadType to props
}

const DownloadModal: React.FC<DownloadModalProps> = ({
  openModal,
  setOpenModal,
  handleDownloadType,
}): ReactElement => {
  console.log(openModal, "openModal");
  
  return (
    <Modal
      open={openModal}
      onCancel={() => {
        console.log("here i am");
        
         setOpenModal(!openModal)}}
      footer={null}
      width={250} // Explicitly set the width to match the content
      closeIcon={null}
      style={{ position: 'absolute', top: '22%', right: '5%' }} // Set position here
      mask={false}
    >
        <div className="w-[200px] h-[88px] relative bg-white rounded-[10px] shadow">
          <div className="w-1 h-36 left-[390px] top-[78px] absolute bg-zinc-100 rounded-[23px]" />
          <div className="left-[20px] top-[20px] absolute flex-col justify-start items-start gap-5 inline-flex">
            <div
              className="justify-start items-center gap-2 inline-flex cursor-pointer"
              onClick={() => {
                handleDownloadType("excel");
                // setOpenModal(false);
              }}
            >
              <div className="text-center text-zinc-900 text-xs font-medium font-['Uncut Sans Variable']">
                Excel (CSV)
              </div>
            </div>
            <div
              className="justify-start items-center gap-2 inline-flex cursor-pointer"
              onClick={() => {
                handleDownloadType("pdf");
                // setOpenModal(false);
              }}
            >
              <div className="text-center text-zinc-900 text-xs font-medium font-['Uncut Sans Variable']">
                PDF
              </div>
            </div>
          </div>
        </div>
    </Modal>
  );
};

export default DownloadModal;
