import { landingInstance } from '../axios/landingInstance'

export const login = async (payload: any) => {
    const response = await landingInstance.post('/Account/Login', payload)
    return response
}

export const forgotPassword = async (payload: any) => {
    const response = await landingInstance.post(
        `/Account/password-reset?email=${payload.email}`
    )
    return response
}


