import { lazy } from "react";
import { Routes, Route} from "react-router-dom";
import ProtectedRoute from "./PotectedRoute";
import TokenPage from "../pages/Token/token";
import ReprintToken from "../pages/ReprintToken/reprintToken";
import ForgotPassword from "../pages/ForgotPassword/forgotPassword";
import AuditLog from "../pages/Report/AuditLog/auditlog";
import CustomerPopulation from "../pages/Report/CustomerPopulation/customerPopulation";
import CustomerSetup from "../pages/Report/CustomerSetup/customerSetup";
import DailySaleReport from "../pages/Report/DailySales/dailySales";
import MonthsWithoutSales from "../pages/Report/MonthWithoutSales/monthsWithoutSales";
import Settings from "../pages/Settings/settings";

const Login = lazy(() => import("../pages/Login/login"));


const AppRoutes = () => {

  return (
    <Routes>
    <Route path="/" element={<Login />} />
    <Route path="/forgot-password" element={<ForgotPassword />} />
    <Route element={<ProtectedRoute />}>
      <Route path="/token" element={<TokenPage />} />
      <Route path="/reprint-credit-token" element={<ReprintToken />} />
      <Route path="/report/audit-log" element={<AuditLog />} />
      <Route path="/report/customer-population" element={<CustomerPopulation />} />
      <Route path="/report/customer-setup" element={<CustomerSetup />} />
      <Route path="/report/daily-sales-point" element={<DailySaleReport />} />
      <Route path="/report/months-without-sales" element={<MonthsWithoutSales />} />
      <Route path="/settings" element={<Settings />} />
    </Route>
  </Routes>
  );
};

export default AppRoutes;
