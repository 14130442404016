export const getSavedEmailPhone = (itemKey: string) => {
    let savedEmailPhone = {}
    try {
        let savedRecord = localStorage.getItem(itemKey)
        if (typeof savedRecord === 'string') {
            return JSON.parse(savedRecord)
        }
        return savedEmailPhone
    } catch (error) {
        
        
        return savedEmailPhone
    }
}



export async function savedLocalEmailPhone(itemKey: string, itemValue: any) {
    localStorage.setItem(itemKey, JSON.stringify(itemValue))
}

export function deleteEmailPhoneState(itemKey: string) {
    localStorage.removeItem(itemKey)
}



