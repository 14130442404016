import { FC, ReactNode, useState } from "react";
import { useLocation } from "react-router-dom";
import { MinusOutlined, MenuOutlined } from "@ant-design/icons";
import { Layout, Menu, theme, Drawer, Button } from "antd";
import reportIcon from "../../assets/img/reportIcon.svg";
import tokenIcon from "../../assets/img/tokenIcon.svg";
import reprintIcon from "../../assets/img/reprint-tokenIcon.svg";
import settingIcon from "../../assets/img/settingIcon.svg";
import logIcon from "../../assets/img/logoutIcon.svg";
import FaanLogo from "../../assets/img/faan-logo.png";
import { getSavedState } from "../../services/localStorage";
import LogOut from "../Modals/LogOutModal/LogOutModal";
import "./content.css";
import { useMediaQuery } from "react-responsive";
import avitechlogo from "../../assets/img/avitech-logo-black.png";

interface ContentProps {
  children?: ReactNode;
  headerName?: string;
  iconName?: string;
}

const items = [
  {
    key: "1",
    icon: <img src={tokenIcon} alt="" />,
    label: (
      <a href="/token" rel="noopener noreferrer">
        Token
      </a>
    ),
  },
  {
    key: "2",
    icon: <img src={reprintIcon} alt="" />,
    label: (
      <a href="/reprint-credit-token" rel="noopener noreferrer">
        Re-print Credit Token
      </a>
    ),
  },
];
const { Content, Sider, Footer } = Layout;

const ContentLayout: FC<ContentProps> = ({
  children,
  headerName,
  iconName,
}) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const savedState = getSavedState("EMSUser");
  const userName = savedState?.data?.fullName;
  const firstName = userName?.split(" ")[0];
  const currentDate = new Date();
  const location = useLocation(); // Get the current location
  const [isLogOutModalVisible, setLogOutModalVisible] = useState(false);
  const businessLocation = savedState?.data?.businessUnitName;
  const [drawerVisible, setDrawerVisible] = useState(false);
  const isLargeScreen = useMediaQuery({ query: "(min-width: 992px)" }); // lg breakpoint

  const getInitials = (name: string) => {
    if (!name) return "";
    const nameParts = name.split(" ");
    const initials = nameParts.map((part) => part.charAt(0)).join("");
    return initials.toUpperCase();
  };

  const userInitials = getInitials(userName);

  const formattedDate = currentDate.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const getDefaultSelectedKeys = () => {
    const { pathname } = location;
    if (pathname === "/token") {
      return ["1"];
    } else if (pathname === "/reprint-credit-token") {
      return ["2"];
    } else if (pathname === "/report/audit-log") {
      return ["5-1"];
    } else if (pathname === "/report/customer-population") {
      return ["5-2"];
    } else if (pathname === "/report/customer-setup") {
      return ["5-3"];
    } else if (pathname === "/report/daily-sales-point") {
      return ["5-4"];
    } else if (pathname === "/settings") {
      return ["6"];
    } else {
      return [];
    }
  };

  const handleLogOutClick = () => {
    setLogOutModalVisible(true);
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
        theme="light"
        width={280}
        style={{
          backgroundColor: "#F9F9F9",
          overflowY: "auto",
          position: "fixed",
          height: "100vh",
        }}
        className="custom-sider"
      >
        <div className="pt-11 px-8 pb-3">
          <img src={FaanLogo} alt="Faan Logo" />
        </div>
        <div className="w-full border-t border-dashed border-neutral-100"></div>
        <Menu
          mode="inline"
          defaultSelectedKeys={getDefaultSelectedKeys()}
          items={items}
          style={{ backgroundColor: "#F9F9F9" }}
        />
        <Menu
          mode="inline"
          defaultSelectedKeys={getDefaultSelectedKeys()}
          items={[
            {
              key: "5",
              label: "Reports",
              icon: <img src={reportIcon} alt="Reports Icon" />,
              children: [
                {
                  key: "5-1",
                  icon: (
                    <MinusOutlined style={{ transform: "rotate(90deg)" }} />
                  ),
                  label: (
                    <a href="/report/audit-log" rel="noopener noreferrer">
                      Audit log
                    </a>
                  ),
                },
                {
                  key: "5-2",
                  icon: (
                    <MinusOutlined style={{ transform: "rotate(90deg)" }} />
                  ),
                  label: (
                    <a
                      href="/report/customer-population"
                      rel="noopener noreferrer"
                    >
                      Customer population
                    </a>
                  ),
                },
                {
                  key: "5-3",
                  icon: (
                    <MinusOutlined style={{ transform: "rotate(90deg)" }} />
                  ),
                  label: (
                    <a href="/report/customer-setup" rel="noopener noreferrer">
                      Customer setup
                    </a>
                  ),
                },
                {
                  key: "5-4",
                  icon: (
                    <MinusOutlined style={{ transform: "rotate(90deg)" }} />
                  ),
                  label: (
                    <a
                      href="/report/daily-sales-point"
                      rel="noopener noreferrer"
                    >
                      Daily sales by sales point
                    </a>
                  ),
                },
                {
                  key: "5-5",
                  icon: (
                    <MinusOutlined style={{ transform: "rotate(90deg)" }} />
                  ),
                  label: (
                    <a
                      href="/report/months-without-sales"
                      rel="noopener noreferrer"
                    >
                      Months without sales
                    </a>
                  ),
                },
              ],
            },
          ]}
          style={{ backgroundColor: "#F9F9F9" }}
        />
        <div className="mt-64" />
        <Menu
          mode="inline"
          defaultSelectedKeys={getDefaultSelectedKeys()}
          items={[
            {
              key: "6",

              label: (
                <a href="/settings" rel="noopener noreferrer">
                  Settings
                </a>
              ),
              icon: <img src={settingIcon} alt="" />,
            },
            // {
            //   key: "7",
            //   label: "Help Center",
            //   // link: "/help-center",
            //   icon: <img src={helpIcon} alt="" />,
            // },
            {
              key: "8",
              label: "Log out",
              link: "/logout",
              icon: <img src={logIcon} alt="" />,
              onClick: handleLogOutClick,
            },
          ].map((item) => ({
            ...item,
            content:
              item.key === "8" ? (
                <span onClick={handleLogOutClick}>{item.label}</span>
              ) : (
                // <Link to={item.link}>{item.label}</Link>
                <span>{item.label}</span>
              ),
          }))}
          style={{ backgroundColor: "#F9F9F9" }}
        />
        <div className="w-full border-t border-dashed border-neutral-100 mt-7"></div>
        <div className="flex items-center gap-3 py-6 px-8">
          <div className="relative">
            <div className="w-8 h-8 bg-white rounded-full border border-stone-50" />
            <div className="absolute left-[10px] top-[7px] text-zinc-900 text-sm font-medium">
              {userInitials}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="text-zinc-900 text-base font-medium">
              {userName}
            </div>
            <div className="text-zinc-900 opacity-50 text-xs font-medium">
              {businessLocation}
            </div>
          </div>
        </div>
      </Sider>
      <Layout style={isLargeScreen ? { marginLeft: 280 } : { marginLeft: 0 }}>
        <Content
          style={{ margin: "24px 16px 0", overflow: "initial" }}
          className="bg-white rounded-[20px]"
        >
          {location.pathname === "/token" ? (
            <div className="flex flex-col gap-1 ml-12 mt-4">
              <div className="text-black text-xl font-medium">
                Hello, {firstName}
              </div>
              <div className="text-black text-opacity-50 text-xs font-normal">
                {formattedDate}
              </div>
            </div>
          ) : (
            <div className="flex gap-3 ml-12 pt-9">
              <img className="w-5 h-5" src={iconName} alt="Icon" />
              <div className="text-center text-zinc-900 text-base font-semibold">
                {headerName}
              </div>
            </div>
          )}
          <div className="w-full border-t border-dashed border-neutral-100 mt-5"></div>
          <div
            className="p-6"
            style={{
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            {children}
          </div>
        </Content>
        <Footer>
          <div className="flex flex-row justify-center items-center gap-6">
            <div className="ml-2 font-light text-sm">Powered by Avitech Software Solutions</div>
            <img src={avitechlogo} alt="Avitech Logo" width={50} height={50} />
          </div>
        </Footer>
      </Layout>
      <LogOut
        isOpen={isLogOutModalVisible}
        setIsOpen={() => setLogOutModalVisible(false)}
      />
      {/* Drawer for small screens */}
      <Drawer
        title="Menu"
        placement="right"
        closable={true}
        onClose={closeDrawer}
        visible={drawerVisible}
        bodyStyle={{ padding: 0 }}
      >
        <Menu
          mode="inline"
          defaultSelectedKeys={getDefaultSelectedKeys()}
          items={items}
          style={{ backgroundColor: "#F9F9F9" }}
        />
        <Menu
          mode="inline"
          defaultSelectedKeys={getDefaultSelectedKeys()}
          items={[
            {
              key: "5",
              label: "Reports",
              icon: <img src={reportIcon} alt="Reports Icon" />,
              children: [
                {
                  key: "5-1",
                  icon: (
                    <MinusOutlined style={{ transform: "rotate(90deg)" }} />
                  ),
                  label: (
                    <a href="/report/audit-log" rel="noopener noreferrer">
                      Audit log
                    </a>
                  ),
                },
                {
                  key: "5-2",
                  icon: (
                    <MinusOutlined style={{ transform: "rotate(90deg)" }} />
                  ),
                  label: (
                    <a
                      href="/report/customer-population"
                      rel="noopener noreferrer"
                    >
                      Customer population
                    </a>
                  ),
                },
                {
                  key: "5-3",
                  icon: (
                    <MinusOutlined style={{ transform: "rotate(90deg)" }} />
                  ),
                  label: (
                    <a href="/report/customer-setup" rel="noopener noreferrer">
                      Customer setup
                    </a>
                  ),
                },
                {
                  key: "5-4",
                  icon: (
                    <MinusOutlined style={{ transform: "rotate(90deg)" }} />
                  ),
                  label: (
                    <a
                      href="/report/daily-sales-point"
                      rel="noopener noreferrer"
                    >
                      Daily sales by sales point
                    </a>
                  ),
                },
                {
                  key: "5-5",
                  icon: (
                    <MinusOutlined style={{ transform: "rotate(90deg)" }} />
                  ),
                  label: (
                    <a
                      href="/report/months-without-sales"
                      rel="noopener noreferrer"
                    >
                      Months without sales
                    </a>
                  ),
                },
              ],
            },
          ]}
          style={{ backgroundColor: "#F9F9F9" }}
        />
        <Menu
          mode="inline"
          defaultSelectedKeys={getDefaultSelectedKeys()}
          items={[
            {
              key: "6",
              label: (
                <a href="/settings" rel="noopener noreferrer">
                  Settings
                </a>
              ),
              icon: <img src={settingIcon} alt="Settings Icon" />,
            },
            {
              key: "8",
              label: "Log out",
              // link: '/logout',
              icon: <img src={logIcon} alt="Log out Icon" />,
              onClick: handleLogOutClick,
            },
          ]}
          style={{ backgroundColor: "#F9F9F9" }}
        />
      </Drawer>
      {/* Hamburger button */}
      <Button
        className="lg:hidden"
        // type="primary"
        onClick={showDrawer}
        style={{
          position: "fixed",
          top: 22,
          right: 16,
          zIndex: 1000,
        }}
      >
        <MenuOutlined />
      </Button>
    </Layout>
  );
};

export default ContentLayout;
