import React, { ReactElement } from "react";
import { Modal } from "antd";
import { deleteLocalState } from "../../../services/localStorage";
import { useNavigate } from "react-router-dom";
import "./modal.css";
import { deleteEmailPhoneState } from "../../../services/verifyEmailPhone";

interface LogOutModalProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

const LogOut: React.FC<LogOutModalProps> = ({
  isOpen,
  setIsOpen,
}): ReactElement => {
  const navigate = useNavigate();

  // const hideModal = () => {
  //     setIsOpen(false);
  //   };

  const handleLogout = () => {
    setIsOpen(false);
    deleteLocalState("EMSUser");
    deleteEmailPhoneState("userContact")
    navigate("/");
  };

  return (
    <>
      <Modal
        title="Logout"
        open={isOpen}
        width={380}
        mask={true}
        onCancel={() => setIsOpen(false)}
        closeIcon={null}
        onOk={handleLogout}
        okText="Yes,logout"
        cancelText="Cancel"
        okButtonProps={{
          style: {
            backgroundColor: "#00A859",
            borderColor: "#00A859",
          },
        }}
        style={{ position: "absolute", top: "30%", right: 0, left: 0 }}

        //  className="h-[0]"
      >
        <div className="custom-modal-body">
          <span>Are you sure you want to log out from the system?</span>
        </div>
      </Modal>
    </>
  );
};

export default LogOut;
