import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { Suspense } from "react";
import { FallbackBg } from "./components/Fallbackbg/Fallbackg.tsx";
import AppRoutes from './routes/appRoutes';
import { ToastContainer } from 'react-toastify'

// import './App.css';



function App() {
  return (
    <BrowserRouter>
    <Suspense fallback={<FallbackBg />}>
    <ToastContainer className="p-3 w-fit text-sm" />
      <AppRoutes />
    </Suspense>
  </BrowserRouter>

  );
}

export default App;
