import { devInstance } from "../axios/axiosInstance";

export const searchAuditLog = async (payload: any) => {
  const response = await devInstance.post("/Reports/auditlog", payload);
  return response;
};

export const getAllUsers = async () => {
  const response = await devInstance.get("/Account/get-users");
  return response;
};

export const getBusinessUnit = async () => {
  const response = await devInstance.get("/Account/get-business-units");
  return response;
};

export const getAllTariffs = async () => {
  const response = await devInstance.get("/Account/get-tarrifs");
  return response;
};

export const searchCustomerPopulation = async (payload: any) => {
  const response = await devInstance.post(
    "/Reports/CustomerPopulation",
    payload
  );
  return response;
};

export const searchCustomerSetup = async (payload: any) => {
  const response = await devInstance.post("/Reports/customer-setup", payload);
  return response;
};

export const searchDailySales = async (payload: any) => {
    const response = await devInstance.post("/Reports/daily-sales-point", payload);
    return response;
  };


  export const searchMonthsWithoutSales = async (payload: any) => {
    const response = await devInstance.post("/Reports/month-without-sales", payload);
    return response;
  }; 

export const downloadAudit = async (payload: any) => {
  const response = await devInstance.post(
    "/Reports/auditlog-download",
    payload,
    {
      responseType: "blob",
    }
  );
  return response;
};

export const downloadCustomerPopulation = async (payload: any) => {
  const response = await devInstance.post(
    "/Reports/CustomerPopulation-download",
    payload,
    {
      responseType: "blob",
    }
  );
  return response;
};

export const downloadCustomerSetup = async (payload: any) => {
  const response = await devInstance.post(
    "/Reports/customer-setup-download",
    payload,
    {
      responseType: "blob",
    }
  );
  return response;
};

export const downloadDailySales = async (payload: any) => {
    const response = await devInstance.post(
      "/Reports/daily-sales-point-download",
      payload,
      {
        responseType: "blob",
      }
    );
    return response;
  };


  export const downloadMonthsWithoutSales = async (payload: any) => {
    const response = await devInstance.post(
      "/Reports/month-without-sales-download",
      payload,
      {
        responseType: "blob",
      }
    );
    return response;
  };

  // api service for settings change of password
  export const accountChangePassword = async (payload: any) => {
    const response = await devInstance.post("/Account/change-password", payload);
    return response;
  };