import React, { ReactElement, useEffect, useState } from "react";
import { Select, AutoComplete, DatePicker, Modal } from "antd";
import { ButtonPrimary } from "../../Button/Button";
import { ButtonOutline } from "../../Button/ButtonOutlined";
import useAllUsers from "../../../hooks/userAllUsers";
import type { DatePickerProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { toast } from "react-toastify";
import './filter.css'


interface FilterModalProps {
  isOpen: boolean;
  setIsOpen: any;
  onApply: (filters: { operatorName: string | undefined, startDate: string | null, endDate: string | null }) => void;
}

const { Option } = Select;

const FilterModal: React.FC<FilterModalProps> = ({
  isOpen,
  setIsOpen,
  onApply,
}): ReactElement => {
  const { RangePicker } = DatePicker;
  const { users, getUsers } = useAllUsers();
  const [options, setOptions] = useState<any[]>([]);
  const [value, setValue] = useState<string | undefined>();
  const [operatorName, setOperatorName] = useState<string | undefined>();
  const [dates, setDates] = useState<[Dayjs | null, Dayjs | null]>([null, null]);
  // const [dates, setDates] = useState<[string | null, string | null]>([null, null]);
  const [dropdownOpen, setDropdownOpen] = useState(false); // State to control dropdown visibility

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (users) {
      setOptions(users); // Initialize options with all users
    }
  }, [users]);

  const handleFocus = () => {
    setDropdownOpen(true); // Open dropdown on focus
  };

  const handleBlur = () => {
    setDropdownOpen(false); // Close dropdown on blur
  };

  const handleSearch = (searchText: string) => {
    if (searchText) {
      const filteredOptions = users.filter((user: any) =>
        user.operatorName.toLowerCase().includes(searchText.toLowerCase())
      );
      setOptions(filteredOptions);
    } else {
      setOptions(users); // Reset to all users if search text is empty
    }
    setDropdownOpen(true); // Ensure dropdown stays open while typing
  };

  const handleOperatorChange = (selectedValue: string) => {
    setOperatorName(selectedValue);
    setValue(selectedValue);
    setDropdownOpen(false); // Close dropdown when an option is selected
  };

  // const handleDateChange = (dates: any, dateStrings: [string, string]) => {
  //   setDates([dateStrings[0], dateStrings[1]]);
  // };
  const handleDateChange = (dates: any, dateStrings: [string, string]) => {
    setDates(dates);
  };

  const disabledMonthsDate: DatePickerProps['disabledDate'] = (current: Dayjs, { from }: { from?: Dayjs }) => {
    const today = dayjs().startOf('day');
  
    // Disable dates beyond today
    if (current.isAfter(today)) {
      return true;
    }
    return false;
  };

  const handleApply = () => {
    if (!dates[0] || !dates[1]) {
      toast.error('Please select both start and end dates.');
      return;
    }
    onApply({
      operatorName,
      startDate: dates[0]?.format('YYYY-MM-DD') || null,
      endDate: dates[1]?.format('YYYY-MM-DD') || null,
    });
    setIsOpen(false); // Close modal after applying filters
  };

  const resetFilters = () => {
    setOperatorName(undefined);
    setDates([null, null]);
    setValue(undefined);
    setOptions(users); // Reset options to all users
    setDropdownOpen(false); // Close dropdown on reset
  };

  return (
    <Modal
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      footer={null}
      width={430} // Explicitly set the width to match the content
      closeIcon={null}
      style={{ position: 'absolute', top: '25%', right: '10%' }} // Set position here
      mask={false}
    >
      <div className="w-[368px] h-[402px] relative shadow">
        <div className="ml-[24px] mt-[10px] pt-4 text-zinc-900 text-base font-semibold font-['Uncut Sans Variable'] leading-tight">
          Filters
        </div>

        <div className="h-[152px] left-[24px] top-[72px] absolute flex-col justify-start items-start gap-5 inline-flex">
          <div className="self-stretch h-[66px] flex-col justify-start items-start gap-1.5 flex">
            <div className="text-center text-zinc-900 text-sm font-medium font-['Uncut Sans Variable'] leading-tight">
              Operator name
            </div>
            <AutoComplete
              value={value}
              placeholder="Select User"
              style={{ width: '320px' }}
              defaultActiveFirstOption={false}
              filterOption={false}
              open={dropdownOpen} // Control dropdown visibility
              onFocus={handleFocus} // Open dropdown on focus
              onBlur={handleBlur} // Close dropdown on blur
              onChange={handleOperatorChange}
              onSearch={handleSearch}
              onSelect={(value) => setDropdownOpen(false)} // Close dropdown when an option is selected
            >
              {options.map((user: any) => (
                <Option key={user.operatorId} value={user.operatorName}>
                  {user.operatorName}
                </Option>
              ))}
            </AutoComplete>
          </div>

          <div className="self-stretch h-[66px] flex-col justify-start items-start gap-1.5 flex custom-range-picker">
            <div className="text-center text-zinc-900 text-sm font-medium font-['Uncut Sans Variable'] leading-tight">
              Period
            </div>
            <RangePicker 
            value={dates}
            onChange={handleDateChange}  
            disabledDate={disabledMonthsDate} 
            className="w-full md:w-[320px]" 
              style={{ width: '100%' }}
            />
          </div>
        </div>

        <div className="left-[90px] top-[342px] absolute justify-start items-start gap-4 inline-flex">
          <ButtonOutline width="120px" label="Reset filter" handleClick={resetFilters} />
          <div className="w-[120px] h-10  justify-center items-center gap-2 flex">
            <ButtonPrimary width="120px" label="Apply" handleClick={handleApply} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FilterModal;
