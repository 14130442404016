import React, { useState } from "react";
import ContentLayout from "../../components/ContentLayout/ContentLayout";
import { ButtonPrimary } from "../../components/Button/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { Spinner } from "../../components/Spinner/Spinner";
import { AxiosError } from "axios";
import * as yup from "yup";
import { getCustomerInfo } from "../../services/token";
import { SideModal } from "../../components/Modals/SideModal";
import ReceiptModal from "../../components/Modals/ReceiptModal/ReceiptModal";
const schema = yup.object().shape({
  meterId: yup.string()
  .matches(/^\d+$/, "Meter number must be numeric")
  .required("meter number is a required field"),
  invoiceNumber: yup.string()
  .matches(/^\d+$/, "Invoice number must be numeric")
  .required("invoice number is a required field"),
});

const TokenPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [apiData, setApiData] = useState<any>(null); // State to hold API data
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [tokenData, setTokenData] = useState(null);

  const { mutateAsync: authenticateUser, isLoading } = useMutation(
    getCustomerInfo,
    {
      onSuccess: async ({ data }) => {
        setApiData(data); // Update state with API data
        setShowModal(true); // Show modal when API call is successful
      },
      onError: (error: AxiosError) => {
        const errorMessage =
          //@ts-ignore
          error?.response?.data?.message || error?.response?.data?.errors?.[0] ||
          "Unknown error occurred";
        toast.error(errorMessage);
      },
    }
  );
  const onSubmit = (data: any) => {
    console.log(data, "myData");

    authenticateUser({
      ...data,
    });
  };

  const handleGenerateTokenSuccess = (data: any) => {
    setTokenData(data);
    setShowReceiptModal(true); // Show the ReceiptModal
        setShowModal(false); // Close the SideModal

  };
  console.log(apiData?.data, "myData");
  console.log(showModal, "modal");
  return (
    <ContentLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-1/8 md:w-1/4 text-black text-[24px] md:text-[32px] font-semibold leading-[24.20px] md:leading-[38.40px] ml-[2px] md:ml-[50px] mt-[50px]">
          Please fill the form for vending ⚡️⚡️
        </div>

        <div className="flex flex-col justify-start items-start  gap-[32px] mt-[20px] md:mt-[40px] mx-auto max-w-[450px] md:ml-[50px]">
          <div className="w-full mb-3">
            <label className="block text-zinc-900 text-sm font-medium mb-2">
              Meter number{" "}
            </label>
            <input
              className="w-full h-10 px-3 py-1.5 rounded-[10px] border border-zinc-200 outline-none"
              type="text"
              {...register("meterId")}
              autoComplete="new"
              onFocus={(e) => e.target.removeAttribute("readonly")}
              readOnly
              onInput={(e) => {
                const input = e.target as HTMLInputElement;
                input.value = input.value.replace(/[^0-9]/g, '');
              }}
            />
            <p className="text-xs text-red-600">{errors?.meterId?.message}</p>
          </div>

          <div className="w-full mb-3">
            <label className="block text-zinc-900 text-sm font-medium mb-2">
              Invoice number{" "}
            </label>
            <input
              className="w-full h-10 px-3 py-1.5 rounded-[10px] border border-zinc-200 outline-none"
              type="text"
              {...register("invoiceNumber")}
              autoComplete="new"
              onFocus={(e) => e.target.removeAttribute("readonly")}
              readOnly
              onInput={(e) => {
                const input = e.target as HTMLInputElement;
                input.value = input.value.replace(/[^0-9]/g, '');
              }}
            />
            <p className="text-xs text-red-600">
              {errors?.invoiceNumber?.message}
            </p>
          </div>
        </div>

        <div className="mt-8 md:ml-[50px]">
          <ButtonPrimary
            label={isLoading ? <Spinner /> : "Validate details"}
            type="submit"
            disabled={isLoading}
            width="41%"
          />
        </div>
      </form>

      {showModal &&
        <SideModal
        isOpen={showModal}
        setIsOpen={setShowModal}
        apiData={apiData?.data}
        onGenerateTokenSuccess={handleGenerateTokenSuccess}
      />}

{showReceiptModal && (
        <ReceiptModal
          onCloseModal={() => setShowReceiptModal(false)}
          tokenData={tokenData}
        />
      )}
    </ContentLayout>
  );
};

export default TokenPage;
