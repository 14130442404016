import { FC, ReactNode } from "react";
import PolygonImage from "../../assets/img/background-vector.svg";
import './landingLayoutcss.css';

const LandingLayout: FC<{
  children: ReactNode;
  contentContainerClass?: string;
}> = ({ children, contentContainerClass }) => {
  return (
    <div className="w-full h-full relative bg-stone-50 main-background">
      {/* Background Image */}
      <div className="absolute inset-0">
        <img 
          src={PolygonImage} 
          alt="background" 
          className="w-full h-full object-cover" 
        />
      </div>

      {/* Content Container */}
      <div className="flex justify-center items-center relative z-10 min-h-screen">
        <div 
          className={`w-[90%] max-w-[548px] h-auto lg:h-[700px] bg-white rounded-[20px] border py-4 md:py-[20px] lg:py-[30px] px-4 md:px-8 lg:px-[50px] ${contentContainerClass}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default LandingLayout;
