import React from 'react';
import type { PaginationProps } from 'antd';
import { Pagination } from 'antd';

const PaginationCom: React.FC<{
pageSize: number;
totalResults: number;
currentPage: number;
handlePageChange:any;
}> = ({pageSize, totalResults, handlePageChange,currentPage}) => {

  const totalPages = Math.ceil(totalResults / pageSize);



  return (
    <div className="flex justify-between items-center w-full">
      <div className="text-gray-600">{`${totalResults} results`}</div>
      <div className="flex items-center">
        <Pagination
          size="small"
          total={totalResults}
          defaultPageSize={pageSize}
          responsive={true}
          onChange={handlePageChange}
          current={currentPage}
          showSizeChanger={false}
        />
        <div className="text-gray-600 ml-4">{`of ${totalPages} pages`}</div>
      </div>
    </div>
  );
};

export default PaginationCom;
