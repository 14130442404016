import { AxiosRequestConfig } from 'axios'
import { getSavedState } from '../services/localStorage'

export const requestInteceptor = (config: AxiosRequestConfig) => {
    const savedState = getSavedState('EMSUser')
    const emsToken = savedState?.data?.token

    config.headers = {
        ...config.headers,
        Authorization: `Bearer ${emsToken}`,
    }
    return config
}
