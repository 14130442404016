import { devInstance } from "../axios/axiosInstance";

export const getCustomerInfo = async (payload: any) => {
    const response = await devInstance.post('/vending/get-customer-meter-Info', payload)
    return response
}

export const generateToken = async (payload: any) => {
    const response = await devInstance.post('/vending/get-token',payload)
    return response
}

export const rePrintToken = async (payload: any) => {
    const response = await devInstance.get(
        `/vending/re-print?MeterNumber=${payload.MeterNumber}`
    )
    return response
}

export const getOTP = async (payload: any) => {
    const response = await devInstance.post(
        `/Account/get-otp?email=${payload.email}`
    )
    return response
}

export const verifyEmail = async (payload: any) => {
    const response = await devInstance.post('/Account/map-user-email',payload)
    return response
}