import React, { ReactElement } from 'react';
import searchIcon from '../../assets/img/searchOutlined.svg';

export function SearchInput({
    value,
    handleSearch,
    customStyle,
    placeholder,
    isDisabled,
    customClass,
}: {
    value: string;
    placeholder?: string;
    handleSearch: any;
    customStyle?: any;
    customClass?: any;
    isDisabled?: boolean;
}): ReactElement {
    return (
        <div className="w-full 2xl:w-80  md:w-[500px] relative h-8 rounded-[10px] shadow">
            <img
                src={searchIcon}
                alt="search"
                className="absolute top-2 left-4 w-4 cursor-pointer"
            />
            <input
                placeholder={placeholder || 'Search'}
                value={value}
                style={{ ...customStyle }}
                className={`h-8 pl-12 text-lg font-Inter rounded-md border w-full focus:outline-none text-[#3A434B] text-[13px] placeholder-[#0D0F11] placeholder:text-[13px] ${customClass}`}
                onChange={(e) => handleSearch(e.target.value)}
                disabled={isDisabled}
            />
        </div>
    );
}
