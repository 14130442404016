import { FC } from "react";
import moment from "moment";

export const CusPopulationTable: FC<{
  apiData: any;
  refetch?: any;
}> = ({ apiData, refetch }) => {
  return (
    <div className="w-full overflow-x-auto">
      <table
        className="w-full font-inter min-w-max"
        style={{ fontSize: "14px", cursor: "pointer" }}
      >
        <thead className={"m-6 bg-neutral-50 text-slate-900"}>
          <tr className="border-b rounded-lg">
            <th className="text-xs text-[#1A1B1D] font-medium py-3">Business unit</th>
            <th className="text-xs text-[#1A1B1D] font-medium">Meter number</th>
            <th className="text-xs text-[#1A1B1D] font-medium">Customer name</th>
            <th className="text-xs text-[#1A1B1D] font-medium">Address</th>
            <th className="text-xs text-[#1A1B1D] font-medium">Tariff</th>
            <th className="text-xs text-[#1A1B1D] font-medium">Operator name</th>
            <th className="text-xs text-[#1A1B1D] font-medium">Date</th>
          </tr>
        </thead>
        <tbody>
          {apiData.map((item: any, index: number) => {
            return (
              <tr className="border-b bg-white border-zinc-100" key={index}>
                <th className="text-xs text-[#1A1B1D] px-2 py-5 font-normal">
                  {item.businessUnit}
                </th>
                <th className="text-xs text-[#1A1B1D] px-2 py-3 font-normal">
                  {item.meter}
                </th>
                <th className="text-xs text-[#1A1B1D] px-2 py-3 font-normal">
                  {item.customerName}
                </th>
                <th className="text-xs text-[#1A1B1D] px-2 py-3 font-normal">
                  {item.address}
                </th>
                <th className="text-xs text-[#1A1B1D] px-2 py-3 font-normal">
                  {item.tariff}
                </th>
                <th className="text-xs text-[#1A1B1D] px-2 py-3 font-normal">
                  {item.operatorName}
                </th>
                <th className="text-xs text-[#1A1B1D] px-2 py-3 font-normal">
                  {moment(item.openDate).format('DD/MM/YYYY hh:mm A')}
                </th>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
