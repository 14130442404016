import { useState } from 'react'
import { useQuery } from 'react-query'
import { getBusinessUnit } from '../services/report'


export default function useAllBusinessUnit() {
    const [buinessUnit, setBusinessUnit] = useState([])

    const { refetch } = useQuery(['fetchBusiness'], () => getBusinessUnit(), {
        enabled: true,
        retry: 2,
        onSuccess: (response) => {
            setBusinessUnit(response?.data?.data)
        },
    })

    const getBusiness = () => {
        refetch()
    }

    return { buinessUnit, getBusiness }
}