import React, { ReactNode } from 'react'
import { Spinner } from '../Spinner/Spinner'

interface IOutineButton {
  label: ReactNode
  handleClick?: any
  type?: any
  isLoading?: boolean
  disabled? : boolean
  color? : string
  className?: any
  onClick?: () => void
  width?: string 
}

export function ButtonPrimary({ label, handleClick, type, isLoading, color, disabled, width }: IOutineButton): JSX.Element {
  return (
    <div className="w-full md:w-auto">
      <button
      className={`flex justify-center items-center  ${disabled? 'bg-opacity-60': ''}  border rounded-lg h-10 text-sm font-medium leading-tight ${color? color : 'bg-[#00A859]  text-white'}`}
      onClick={handleClick}
      type={type}
      disabled={isLoading || disabled}
      style={{ width }} 
    >
      {isLoading ? (<Spinner />) : label}
    </button>
    </div>
    );
}
