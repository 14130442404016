import { useState } from 'react'
import { useQuery } from 'react-query'
import { getAllUsers } from '../services/report'

export default function useAllUsers() {
    const [users, setUsers] = useState([])

    const { refetch } = useQuery(['fetchActions'], () => getAllUsers(), {
        enabled: true,
        retry: 2,
        onSuccess: (response) => {
            setUsers(response?.data?.data)
        },
    })

    const getUsers = () => {
        refetch()
    }

    return { users, getUsers }
}