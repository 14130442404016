import React, { useState } from "react";
import LandingLayout from "../../components/Landinglayout/LandingLayout";
import faanLogo from "../../assets/img/faan-logo.png";
import groutDots from "../../assets/img/group-dots.png";
import { ButtonPrimary } from "../../components/Button/Button";
import callIcon from "../../assets/img/Phone.svg";
import mailIcon from "../../assets/img/Letter.svg";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { saveLocalState } from "../../services/localStorage";
import { useMutation } from "react-query";
import { Spinner } from "../../components/Spinner/Spinner";
import { AxiosError } from "axios";
import arrowLeftIcon from "../../assets/img/arrowLeft.svg";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../services/auth";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Enter valid email")
    .required("Email is a required field"),
});

const ForgotPassword = () => {
    const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { mutateAsync: authenticateUser, isLoading } = useMutation(
    forgotPassword, 
    {
      onSuccess: async ({ data }) => {
        // setApiData(data); // Update state with API data
        // setShowModal(true); // Show modal when API call is successful
        toast.success("Your new password has been emailed to you.");
      },
    onError: (error: AxiosError) => {
      const errorMessage =
        //@ts-ignore
        error?.response?.data?.message || error?.response?.data?.errors?.[0] ||
        "Unknown error occurred";
      toast.error(errorMessage);
    },
  });

  const onSubmit = (data: any) => {
    console.log(data, "myData");

    authenticateUser({
      ...data,
    });
  };

  return (
    <LandingLayout>
      <div className="flex flex-row justify-center gap-4">
        <img src={groutDots} alt="" />
        <img src={faanLogo} alt="" />
        <img src={groutDots} alt="" />{" "}
      </div>
      <div className="text-center text-zinc-900 text-[24px] md:text-[32px] font-semibold font-['Uncut Sans Variable'] mt-[20px] md:mt-[45px]">
        Forgot password?
      </div>
      <div className="text-center text-zinc-900 text-opacity-70 text-sm font-medium font-['Uncut Sans Variable'] mt-[16px] px-12">
        Enter your email address linked to this account and we’ll send you a new
        password
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col justify-start items-start gap-[32px] mt-[20px] md:mt-[40px] mx-auto max-w-[450px]">
        <div className="w-full mb-3">
            <label className="block text-zinc-900 text-sm font-medium mb-2">
              Email{" "}
            </label>
            <input
              className="w-full h-10 px-3 py-1.5 rounded-[10px] border border-zinc-200 outline-none"
              type="text"
              {...register("email")}
            />
            <p className="text-xs text-red-600">{errors?.email?.message}</p>
          </div>
        </div>

        <div className="mt-8">
          <ButtonPrimary
            label={isLoading ? <Spinner /> : "Reset password"}
            type="submit"
            disabled={isLoading}
            width="100%" // This will make the button full width on smaller screens
          />
        </div>
        <div className="h-5 justify-center gap-2 flex mt-[42px] cursor-pointer"
                  onClick={() => navigate("/")}

        >
          <img src={arrowLeftIcon} alt="" className="w-5 h-5 relative" />
          <div className="text-center text-emerald-600 text-sm font-medium font-['Uncut Sans Variable']">
            Return to login
          </div>
        </div>
      </form>

      <div className="flex flex-col md:flex-row justify-start items-center gap-4 mt-8 md:mt-[100px] mx-auto max-w-[450px]">
      <a
          href="tel:07046270753"
          className="flex justify-start items-center gap-2.5"
        >
          <img src={callIcon} alt="Call Icon" className="w-5 h-5" />
          <div className="text-neutral-700 text-sm font-normal">
            0704 627 0753
          </div>
        </a>
        <div className="justify-start items-center gap-[5px] flex">
          <a
            href="mailto:support@avitechng.com"
            className="flex items-center gap-[5px]"
          >
            <img src={mailIcon} alt="" className="w-5 h-5 relative" />
            <div className="text-neutral-700 text-sm font-normal font-['Uncut Sans Variable'] leading-[18.83px]">
              support@avitechng.com
            </div>
          </a>
        </div>
      </div>

      <div className="w-[450px] h-[0px] border border-zinc-200 mt-[15px] mb-[15px]"></div>
      <div className="text-neutral-700 text-sm font-normal font-['Uncut Sans Variable'] leading-[18.83px]">
        © Avitech 2024. All rights reserved.
        <br />
        Privacy Policy | Customer Awareness Policy
      </div>
    </LandingLayout>
  );
};

export default ForgotPassword;
