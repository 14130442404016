import React, { ReactElement, useEffect, useState } from "react";
import { Select, AutoComplete, DatePicker, Modal } from "antd";
import { ButtonPrimary } from "../../Button/Button";
import { ButtonOutline } from "../../Button/ButtonOutlined";
import useAllUsers from "../../../hooks/userAllUsers";
import useAllBusinessUnit from "../../../hooks/useAllBusiness";
import useAllTariff from "../../../hooks/useAllTariffs";
import dayjs, { Dayjs } from "dayjs";
import type { DatePickerProps } from "antd";
import { toast } from "react-toastify";
import './cus.css'


interface FilterModalProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  onApply: (filters: {
    operatorName: string | undefined;
    startDate: string | null;
    endDate: string | null;
    businessName: string | null;
    tariffName: number | null;
  }) => void;
}

const { Option } = Select;

const CusPopulationModal: React.FC<FilterModalProps> = ({
  isOpen,
  setIsOpen,
  onApply,
}): ReactElement => {
  const { RangePicker } = DatePicker;
  const { users, getUsers } = useAllUsers();
  const { buinessUnit, getBusiness } = useAllBusinessUnit();
  const { tariffs, getTariffs } = useAllTariff();
  const [value, setValue] = useState<string | undefined>();
  const [dates, setDates] = useState<[Dayjs | null, Dayjs | null]>([
    null,
    null,
  ]);
  const [operatorName, setOperatorName] = useState<string | undefined>();
  const [tariffCode, setTariffCode] = useState<string>("");
  const [businessName, setBusinessName] = useState<string | null>(null);

  const [userOptions, setUserOptions] = useState<any[]>([]);
  const [businessOptions, setBusinessOptions] = useState<any[]>([]);
  const [tariffOptions, setTariffOptions] = useState<any[]>([]);
  const [options, setOptions] = useState<any[]>([]);
  const [dropdownOpen, setDropdownOpen] = useState(false); // State to control dropdown visibility
  const [dropdownOpenBus, setDropdownOpenBus] = useState(false); // State to control dropdown visibility
  const [dropdownOpenTaf, setDropdownOpenTaf] = useState(false); // State to control dropdown visibility

  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const [businessDropdownOpen, setBusinessDropdownOpen] = useState(false);
  const [tariffDropdownOpen, setTariffDropdownOpen] = useState(false);

  const [tariffMap, setTariffMap] = useState<{ [key: string]: number }>({});

  useEffect(() => {
    getTariffs();
    getUsers();
    getBusiness();
  }, []);

  useEffect(() => {
    if (users) {
      setOptions(users); // Initialize options with all users
    }
  }, [users]);

  useEffect(() => {
    if (buinessUnit) {
      setBusinessOptions(buinessUnit); // Initialize options with all users
    }
  }, [buinessUnit]);

  useEffect(() => {
    if (tariffs) {
      setTariffOptions(tariffs); // Initialize options with all users
    }
  }, [tariffs]);

  useEffect(() => {
    const map: { [key: string]: number } = {};
    tariffs.forEach((tariff: any) => {
      map[tariff.tariffCode] = tariff.tariffID;
    });
    setTariffMap(map);
  }, [tariffs]);

  const handleBusinessFocus = () => {
    setDropdownOpenBus(true); // Open dropdown on focus
  };

  const handleBusinessBlur = () => {
    setDropdownOpenBus(false); // Close dropdown on blur
  };

  const handleDateChange = (dates: any, dateStrings: [string, string]) => {
    setDates(dates);
  };

  const handleOperatorChange = (selectedValue: string) => {
    setOperatorName(selectedValue);
    setValue(selectedValue);
    setUserDropdownOpen(false); // Close dropdown when an option is selected
  };
  const handleFocus = () => {
    setDropdownOpen(true); // Open dropdown on focus
  };

  const handleBlur = () => {
    setDropdownOpen(false); // Close dropdown on blur
  };

  const handleFocusTarif = () => {
    setDropdownOpenTaf(true); // Open dropdown on focus
  };

  const handleBlurTarif = () => {
    setDropdownOpenTaf(false); // Close dropdown on blur
  };
  const handleBusinessChange = (value: string) => {
    setBusinessName(value || null);
    setBusinessDropdownOpen(false); // Close dropdown when an option is selected
  };

  const handleTariffChange = (value: string) => {
    setTariffCode(value);
    setTariffDropdownOpen(false); // Close dropdown when an option is selected
  };

  const handleSearch = (searchText: string) => {
    if (searchText) {
      const filteredOptions = users.filter((user: any) =>
        user.operatorName.toLowerCase().includes(searchText.toLowerCase())
      );
      setOptions(filteredOptions);
    } else {
      setOptions(users); // Reset to all users if search text is empty
    }
    setDropdownOpen(true); // Ensure dropdown stays open while typing
  };

  const handleSearchBusiness = (searchText: string) => {
    if (searchText) {
      const filteredOptions = buinessUnit.filter((business: any) =>
        business.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setBusinessOptions(filteredOptions);
    } else {
      setBusinessOptions(buinessUnit); // Reset to all users if search text is empty
    }
    setDropdownOpenBus(true); // Ensure dropdown stays open while typing
  };

  const handleSearchTariffs = (searchText: string) => {
    if (searchText) {
      const filteredOptions = tariffs.filter((tariff: any) =>
        tariff.tariffCode.toLowerCase().includes(searchText.toLowerCase())
      );
      setTariffOptions(filteredOptions);
    } else {
      setTariffOptions(tariffs); // Reset to all users if search text is empty
    }
    setDropdownOpenTaf(true); // Ensure dropdown stays open while typing
  };

  const disabledMonthsDate: DatePickerProps["disabledDate"] = (
    current: Dayjs,
    { from }: { from?: Dayjs }
  ) => {
    const today = dayjs().startOf("day");

    // Disable dates beyond today
    if (current.isAfter(today)) {
      return true;
    }
    return false;
  };

  const handleApply = () => {
    if (!dates[0] || !dates[1]) {
      toast.error("Please select both start and end dates.");
      return;
    }
    const tariffID = tariffMap[tariffCode] || null;
    onApply({
      operatorName,
      startDate: dates[0]?.format("YYYY-MM-DD") || null,
      endDate: dates[1]?.format("YYYY-MM-DD") || null,
      businessName,
      tariffName: tariffID,
    });
    setIsOpen(false);
  };

  const resetFilters = () => {
    setOperatorName(undefined);
    setDates([null, null]);
    setBusinessName(null);
    setTariffCode("");
    setUserDropdownOpen(false);
    setBusinessDropdownOpen(false);
    setTariffDropdownOpen(false);
    setOptions(users); // Reset options to all users
    setDropdownOpen(false); // Close dropdown on reset
    setValue(undefined);
  };

  return (
    <Modal
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      footer={null}
      width={430} // Explicitly set the width to match the content
      closeIcon={null}
      style={{ position: "absolute", top: "25%", right: "10%" }} // Set position here
      mask={false}
    >
      <div className="w-[368px] h-[511px] relative bg-white rounded-[20px] shadow">
        <div className="ml-[24px] mt-[10px] pt-4 text-zinc-900 text-base font-semibold font-['Uncut Sans Variable'] leading-tight">
          Filters
        </div>

        <div className="h-[152px] left-[24px] top-[72px] absolute flex-col justify-start items-start gap-5 inline-flex">
          <div className="self-stretch h-[66px] flex-col justify-start items-start gap-1.5 flex">
            <div className="text-center text-zinc-900 text-sm font-medium font-['Uncut Sans Variable'] leading-tight">
              Business unit
            </div>
            <AutoComplete
              value={businessName}
              placeholder="Select business unit"
              style={{ width: "320px" }}
              defaultActiveFirstOption={false}
              filterOption={false}
              open={dropdownOpenBus}
              onFocus={handleBusinessFocus} // Open dropdown on focus
              onBlur={handleBusinessBlur} // Close dropdown on blur
              onChange={handleBusinessChange}
              onSearch={handleSearchBusiness}
              onSelect={(value) => setDropdownOpenBus(false)} // Close dropdown when an option is selected
            >
              {businessOptions.map((business: any) => (
                <Option key={business.buid} value={business.buid}>
                  {business.buid}
                </Option>
              ))}
            </AutoComplete>
          </div>

          <div className="self-stretch h-[66px] flex-col justify-start items-start gap-1.5 flex">
            <div className="text-center text-zinc-900 text-sm font-medium font-['Uncut Sans Variable'] leading-tight">
              Tariff
            </div>
            <AutoComplete
              value={tariffCode}
              placeholder="Select tariff"
              style={{ width: "320px" }}
              defaultActiveFirstOption={false}
              filterOption={false}
              open={dropdownOpenTaf}
              onFocus={handleFocusTarif} // Open dropdown on focus
              onBlur={handleBlurTarif} // Close dropdown on blur
              onChange={handleTariffChange}
              onSearch={handleSearchTariffs}
              onSelect={(value) => setDropdownOpenTaf(false)} // Close dropdown when an option is selected
            >
              {tariffOptions.map((tariff: any) => (
                <Option key={tariff.tariffCode} value={tariff.tariffCode}>
                  {tariff.tariffCode}
                </Option>
              ))}
            </AutoComplete>
          </div>

          <div className="self-stretch h-[66px] flex-col justify-start items-start gap-1.5 flex">
            <div className="text-center text-zinc-900 text-sm font-medium font-['Uncut Sans Variable'] leading-tight">
              Operator name
            </div>
            <AutoComplete
              value={value}
              placeholder="Select User"
              style={{ width: "320px" }}
              defaultActiveFirstOption={false}
              filterOption={false}
              open={dropdownOpen} // Control dropdown visibility
              onFocus={handleFocus} // Open dropdown on focus
              onBlur={handleBlur} // Close dropdown on blur
              onChange={handleOperatorChange}
              onSearch={handleSearch}
              onSelect={(value) => setDropdownOpen(false)} // Close dropdown when an option is selected
            >
              {options.map((user: any) => (
                <Option key={user.operatorId} value={user.operatorName}>
                  {user.operatorName}
                </Option>
              ))}
            </AutoComplete>
          </div>

          <div className="self-stretch h-[66px] flex-col justify-start items-start gap-1.5 flex">
            <div className="text-center text-zinc-900 text-sm font-medium font-['Uncut Sans Variable'] leading-tight">
              Period
            </div>
            <RangePicker
              value={dates}
              onChange={handleDateChange}
              disabledDate={disabledMonthsDate}
              className="w-full sm:w-[320px]" 
              style={{ width: '100%' }}            />
          </div>
        </div>

        <div className="left-[90px] top-[420px] absolute justify-start items-start gap-4 inline-flex">
          <ButtonOutline
            width="120px"
            label="Reset filter"
            handleClick={resetFilters}
          />
          <div className="w-[120px] h-10 justify-center items-center gap-2 flex">
            <ButtonPrimary
              width="120px"
              label="Apply"
              handleClick={handleApply}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CusPopulationModal;
