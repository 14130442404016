import axios, { AxiosRequestConfig } from "axios";
import { requestInteceptor } from "./requestInterceptor";
import { responseErr, responseInterceptor } from "./responseInterceptor";

export const devInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? `${process.env.REACT_APP_API_URL}`
      : //@ts-ignore
        `${window.env?.apiURL}/api`,
  // `https://emsapitest.avitechng.com/api`,
  headers: {},
});

devInstance.interceptors.request.use(
  //@ts-ignore
  (config: AxiosRequestConfig) => requestInteceptor(config),
  (error: any) => {
    return Promise.reject(error);
  }
);
devInstance.interceptors.response.use(
  (res) => responseInterceptor(res),
  async (err) => responseErr(err, devInstance, `$`)
);
