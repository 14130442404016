import { FC } from 'react'
import NoData from '../../assets/img/emptyIcon.svg'
import EmptyWidget from '../../assets/img/empty-widget.svg'
export const EmptyState: FC<{
    message?: string
    variant?: 'widget' | 'fullscreen'
}> = ({ message = 'No Record Found', variant = 'fullscreen' }) => {
    if (variant === 'widget') {
        return (
            <div className="flex flex-col gap-5 items-center justify-center px-6  h-full ">
                <img src={EmptyWidget} className="" alt="no data" />
                <div className="text-xl font-semibold text-gray-400">
                    {message}
                </div>
                <div className="text-sm font-medium leading-none">
                    We couldn’t find any record matching your query. Try another query.
                    </div>
            </div>
        )
    }
    return (
        <div className="flex flex-col gap-5 items-center justify-center px-6 py-12 h-[60vh] border-[#F1F2F3] border ">
            <img src={NoData} alt="no data" />
            <div className="text-xl font-semibold text-zinc-900">
                    {message}
                </div>
                <div className="text-sm font-medium leading-none">
                    We couldn’t find any record matching your query. Try another query.
                    </div>            
        </div>
    )
}
