// helper.tsx

export const roundToTwoDecimalPlaces = (value: number | undefined): number | undefined => {
    if (value === undefined) return undefined;
    return Math.round(value * 100) / 100;
  };
  
  export const addSpacesToCamelCase = (str: string) => {
    return str.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase();
  };
  
  export const numberWithCommasOnly = (x:any, precision:any) => {
    if (!x && x !== 0) return "";

    if (precision) {
      x = Number(x).toFixed(precision);
    }

    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }